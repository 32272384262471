import { render, staticRenderFns } from "./CommissionPayment.vue?vue&type=template&id=533b7bd0&scoped=true"
import script from "./CommissionPayment.vue?vue&type=script&lang=js"
export * from "./CommissionPayment.vue?vue&type=script&lang=js"
import style0 from "./CommissionPayment.vue?vue&type=style&index=0&id=533b7bd0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533b7bd0",
  null
  
)

export default component.exports