<template>
    <tbody class="table-font">
        <tr>
            <td>{{index+1}}</td>
            <td>{{moment(item.transactiondate).format("DD-MM-YYYY")}}</td>
            <td><i class='bx bx-rupee'></i> {{totalpaid(item)}}</td>
            <td><i class='bx bx-rupee'></i> {{item.amount}}</td>
            <td><span v-if="item.paymentregister!=null">{{item.paymentregister.name}}</span></td>
            <td><i class='bx bx-rupee'></i> {{item.additionalpay}}</td>
            <td>{{item.additionalgift}}<span v-if="item.gifttype==3">/- Recharge<br>{{item.mobileno}}</span></td>
            <td>{{item.additionalscheme}}</td>
            <td>{{item.remark}}</td>
            <td><button type="button" class="badge btn-success btn-xs" @click="edit(item)"><i class="fa fa-edit"></i> Edit</button></td>
            <td style="padding:0px">
                <button v-if="clickpdf==false" class="btn btn-print" @click="generatepdf(item)"><i class="fa fa-print icon-print"></i></button>
                <span v-if="clickpdf" class="spinner"></span>
            </td>
        </tr>
        <tr v-if="showpaymentform">
            <td colspan="12">
                <advisor-payment-form :itemid="item.customer"></advisor-payment-form>
            </td>
        </tr>
    </tbody>
</template>
<script>
import moment from 'moment';
import AdvisorPaymentForm from './AdvisorPaymentForm.vue';
export default {
    components:{AdvisorPaymentForm},
    props:['item','index'],
    data() {
        return {
            moment:moment,
            clickpdf:false,
            showpaymentform:false
        }
    },
    methods: {
        totalpaid(item){
            if(item!=[]){
                if (typeof item.additionalgift !== 'string') {
                    return parseInt(item.amount) + parseInt(item.additionalpay!=null?item.additionalpay:0);
                }
                
                const num = Number(item.additionalgift!=null?item.additionalgift:0);
                
                if (Number.isInteger(num) && num > 0) {
                    return parseInt(item.amount) + parseInt(item.additionalpay!=null?item.additionalpay:0)+ parseInt(num);
                }
                return parseInt(item.amount) + parseInt(item.additionalpay!=null?item.additionalpay:0)
                
            }else{
                return 0
            }
        },
        generatepdf(item){
            this.clickpdf=true
            window.axios({
                url: 'https://cn373vzw7i.execute-api.ap-south-1.amazonaws.com/api/vsdigipdf/advisor/pdf?id='+item.id, // File URL Goes Here
                method: 'GET',
                responseType: 'blob',
            })
            .then(response=>{
                this.clickpdf=false
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
            
                fileLink.setAttribute('download', 'file.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
    
            })
            .catch((err)=>this.errorfunction(err));
        },
        errorfunction(){
            this.clickpdf=false
        },
        edit(item){
            this.showpaymentform = !this.showpaymentform
            this.$store.commit("assignedititem",item)
        }
    },
}
</script>
<style scoped>
.icon-print{
    font-size: 25px;
    color: #16768b;
}
</style>