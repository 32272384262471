<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-list-alt animated bounceInDown show-info"></i>
                Advisor
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row" style="padding-top:18px;justify-content:flex-end;">
                    <div class="col-md-2" style="margin-right:25px">   
                        <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                    </div> 
                    <div class="col-md-2" style="margin-right:25px;">             
                        <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i>  Refresh</button>
                    </div>
                </div>
           </div>
        </div>
    </div>
        <div class="text-center detailborder">
            <h3>Payment History  </h3>
        </div> 
         <div class="panel panel-cascade pt-20">
           <!-- <div class="col-md-12">
                <div class="col-md-12 row">
                    <div class="col-md-4 row text-right">
                        <label><strong>From Date</strong></label>
                    <input type="date" class="form-cascade-control input-box-short" v-model="fromdate">
                    </div>
                    <div class="col-md-4 row">
                        <label><strong>To Date</strong></label>
                    <input type="date" class="form-cascade-control input-box-short" v-model="todate">
                    </div>
                    <div class="col-md-2">
                        <button @click="search()" class="btn bg-success text-white username btnsearch" style="margin-top:31px;" type="button">Search</button>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="col-md-12 flex-between-row">
            <div>
                <label>Name</label>  :  
                <span style="font-weight:bold">
                    <span v-if="this.advisorid.customerid!=null">{{this.advisorid.customer.name}}</span>
                    <span v-else>{{this.advisorid.name}}</span>
                </span>
            </div>
            <div>
                <label>Total Amount Paid</label>  :  <span style="font-weight:bold"> <i class='bx bx-rupee'> </i>{{this.totalpaidamount}}</span>
            </div>
        </div>
        <div class="col-md-12 table-responsive">
            <div class=" panel">
                <div class="  panel-body" style="overflow-x:auto;padding-left:0px">
                    <table class="table table-bordered table-hover table-striped display">
                        <thead class="table-font" style="font-size:13px;">
                            <tr>
                                <th>#</th>
                                <th>Payment Date</th>
                                <th >Total Paid</th>
                                <th >Amount 1% + Incentive</th>
                                <th>Payment Type</th>
                                <th>Additional Payout</th>
                                <th>Additional Gift</th>
                                <th>Additional Scheme</th>
                                <th>Remark</th>
                                <th>Edit</th>
                                <th>Print Receipt</th>
                            </tr>
                        </thead>
                        <commission-payment-row v-for="(item,index) in payment" :key="item.id" :item="item" :index='index'></commission-payment-row>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import CommissionPaymentRow from './CommissionPaymentRow.vue'
import Constants from '../../components/utilities/Constants.vue'
export default {
    mixins:[Constants],
    components:{CommissionPaymentRow},
    // props:['id'],
    data() {
        return {
            moment:moment,
            payment:[],
            // clickpdf:false
        }
    },
    computed:{
        ...mapGetters([
		    'viewno','downlinecount','commissiondetails','attributes','advisorid'
	    ]),
        totalpaidamount(){
            if(this.payment!=null && this.payment.length>0){
                let amt=0.0;
                this.payment.forEach(element => {
                    amt=(amt-0) + parseFloat(element.amount) + parseFloat(element.additionalpay);
                    if(element.gifttype==2 || element.gifttype==3){
                        amt=amt+ parseFloat(element.additionalgift)
                    }
                });
                amt=(amt).toFixed(2);
                return amt
            }else{
                return ''
            }
        },
    },
    mounted() {
        this.refresh();
    },
    methods:{
        
        refresh(){
            this.$store.commit("assignloadingstatus",1)
            
            let param={}
            if(this.advisorid.customerid!=null){
                param={id:this.advisorid.customerid}
            }else{
                param={id:this.advisorid.id}
            }
            
            this.$http.post('api/fetch/fetchpaymentcommission',param)
            .then((response) => this.processledgerResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processledgerResponse(data){
            //console.log(data)
            this.$store.commit("assignloadingstatus",0)
            this.payment=data
        },
        
        back(){
            this.$router.back();
        }
        
    }
}
</script>
<style scoped>
.detailborder{
    padding: 15px;
    background-color: #eab132f5;
}
.amt{
    color:#485259;
    font-weight: 700;
}
</style>